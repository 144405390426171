import {
  motion,
  useMotionTemplate,
  useScroll,
  useSpring,
  useTransform,
} from "framer-motion";

export default function HeroFader(props: { children: React.ReactNode }) {
  const { scrollYProgress } = useScroll({
    offset: ["350px", "0px"],
  });

  const opacity = useSpring(scrollYProgress, { stiffness: 500, damping: 90 });
  const blurValue = useTransform(scrollYProgress, [0.5, 1], [8, 0]);
  const scaleValue = useSpring(
    useTransform(scrollYProgress, [0.75, 1], [1.1, 1.25]),
    { stiffness: 400, damping: 90 },
  );
  const filter = useMotionTemplate`blur(${blurValue}px)`;
  const transform = useMotionTemplate`scale(${scaleValue})`;

  return (
    <div className="bg-background-dark overflow-hidden">
      <motion.div
        style={{ opacity, filter, transform, WebkitTransform: transform }}
      >
        <div className="">{props.children}</div>
      </motion.div>
    </div>
  );
}
